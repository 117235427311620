import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { QuestionList, EventList } from './info';
import { SurveyService } from './survey.service';
import { Moment } from 'moment';
import { App } from '@capacitor/app';
import * as _moment from 'moment';
import { EventsService } from './events.service';
const moment = _moment;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  //* VERSIONE APP *//
  appVersion;



  devicePwd = 'aw-22!*';
  deviceVar = 'device_id';
  deviceId;
  page;
  surveyStatus = 0;
  surveyMessage = 'Caricamento...';
  flErrPwd = false;
  questions: Array<QuestionList> = [];
  events: Array<EventList> = [];
  todayDate = moment();
  delay10h = 36000000;
  delay5min = 300000;
  delay1min = 60000;
  delay30sec = 30000;

  update = setInterval(() => {
    this.checkSurveyQuestion();
    this.checkEvents();
    }, this.delay10h);

  h = setInterval(() => {
    this.goHome();
    }, this.delay1min);

  // sendclick = setInterval(() => {
  //   this.eventsService.invioClick();
  //   }, this.delay30sec);

  constructor(public router: Router,
              public alertController: AlertController,
              public surveyService: SurveyService,
              public eventsService: EventsService,
              public http: HttpClient)
  {
    this.getVersion();

  }

  ngOnInit(): void {
    this.checkDeviceId();
    this.checkSurveyQuestion();
    this.checkEvents();
    this.eventsService.invioClick();
  }

  checkDeviceId(){
    this.deviceId = localStorage.getItem(this.deviceVar) ?? '';
    if(this.deviceId === '')
    {
      this.presentAlertPrompt();
    }
  }

  setDeviceId(id, pwd)
  {
    if(pwd === this.devicePwd)
    {
      localStorage.setItem(this.deviceVar, id);
    }
  }

  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      cssClass: 'alert-login',
      backdropDismiss: this.deviceId ? true : false,
      header: this.deviceId ? 'Id Dispositivo: ' + this.deviceId : 'Inserire Id Dispositivo',
      inputs: [
        {
          name: 'id',
          type: 'number',
          placeholder: this.deviceId ? 'Nuovo id dispositivo' : 'Id Dispositivo',
        },
        {
          name: 'pwd',
          type: 'password',
          placeholder: 'Password'
        },
      ],
      buttons: [
        {
          text: this.deviceId ? 'Modifica Id' : 'Salva Id',
          handler: data => {
            console.log(data);
            this.setDeviceId(data.id, data.pwd);
          }
        }
      ]
    });

    await alert.present();
    await alert.onWillDismiss();
    this.checkDeviceId();
  }

  checkSurveyQuestion()
  {
    this.surveyService.setQuestions();
  }

  resetTimer(){
    clearInterval(this.h);
    this.h = setInterval(() => {
      this.goHome();
      }, this.delay1min);
  }

  goHome(){
    this.router.navigateByUrl('/home', {replaceUrl: true});
    this.surveyService.setQuestions();
    console.log('back home');
  }

  checkEvents(){
    this.eventsService.setEvents(this.deviceId);
  }

  getVersion() {
    App.getInfo().then(
       (success) => {
         this.appVersion = success.version;
       }
     );
  }
}
