import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuestionList, AnswerOptions } from './info';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public questions: Array<QuestionList> = [];

  constructor(public http: HttpClient) { }

  getQuestions(): Array<QuestionList>
  {
    return this.questions;
  }

  setQuestions()
  {
    this.questions = [];

    this.http.get(environment.apiUrl + '/getQuestions').toPromise().then(
      data => {
        (data as any).forEach(x => {
          const options = [];
          x.options.forEach(o=>{
            options.push(({id: o.id, answer: o.answer} as AnswerOptions));
          });

          const question: QuestionList = {
            questionId: +x.id,
            answerType: x.answer_type,
            question: x.question,
            answer: '',
            radioSelected: null,
            rating: 1,
            options,
          };
          this.questions.push(question);
        });
      console.log(this.questions);
    },
    err => {
    });
    // this.questions =  ['domanda 1', 'domanda 2', 'domanda 3'];
  }

  setRating(n, value)
  {
    this.questions[n].rating = value;
  }

}

