import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventList } from './info';

import * as _moment from 'moment';
import { environment } from 'src/environments/environment';
const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public events: Array<EventList> = [];
  public deviceId: number;
  public clicked: Array<string> = [];


  constructor(public http: HttpClient) { }



  getEvents(): Array<EventList>
  {
    return this.events;
  }

  async invioClick() {
    this.events.forEach(evento => {
      //const value = localStorage.getItem('evento'+evento.id);

      //localStorage.setItem('post'+evento.id, value.toString()); //poi da commentare
      console.log('Click per l\'evento '+ evento.id+': '+evento.click);

      this.http.post(environment.apiUrl + '/saveClick', {
          'event_id': evento.id,
          'value': evento.click,
        }).subscribe(data => {
          localStorage.setItem('evento'+evento.id, '0');
          evento.click = 0;
        },err => {
      });

    });
  }
  setEvents(devid: number)
  {
    this.deviceId = devid;
    this.events = [];

    this.http.get(environment.apiUrl + '/getEvents').toPromise().then(
      data => {
        (data as any).forEach(x => {
          let colore;
          if(x.color && x.color !== '')
          {
            colore = x.color;
          }
          else
          {
            colore = x.aw_type.color;
          }
          const event: EventList = {
            id: +x.id,
            typeId: +x.type_id,
            type: x.aw_type.name,
            customTypeName: x.custom_type_name,
            start: x.start !== null ? moment(x.start) : null,
            end: x.end !== null ? moment(x.end) : null,
            name: x.name,
            title: x.title,
            text: x.text,
            color: colore,
            sponsor: x.sponsor,
            ytId: x.yt_id,
            subText: x.sub_text,
            click: Number(localStorage.getItem('evento'+x.id)?localStorage.getItem('evento'+x.id):0),
          };
          this.events.push(event);
        });
      console.log(this.events);
    },
    err => {
    });
    // this.events =  ['domanda 1', 'domanda 2', 'domanda 3'];
  }

  getDevId()
  {
    return this.deviceId;
  }
}
